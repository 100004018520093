<template>
  <section class="home-content">
    <div class="container">
      <h2 >Inzerát: {{add.steps[1].nazev}}</h2>
      <p v-if="requestType == 1">Žádost o úpravu inzerátu</p>
      <p v-if="requestType == 2">Žádost o zrušení inzerátu</p>
      <form class="form-label form-js-label">
        <div class="row">
          <fieldset :class="{error: validated && message.length == 0, succes: message.length > 0}">
            <textarea rows="15" class="uk-textarea" v-model="message" name="message"></textarea>
            <label v-if="requestType == 1" for="message">Napište nám, co máme pro vás změnit.</label>
            <label v-if="requestType == 2" for="message">Napište nám, proč chcete inzerát zrušit.</label>
          </fieldset>
        </div>
        <div class="row end uk-margin-top uk-flex-between uk-flex">
          <router-link :to="{name: 'viewonly', params: {id: add.id}}" class="button white small">Zpět na detail</router-link>
          <a @click="send()" class="button small">Odeslat</a>
        </div>
      </form>
    </div>
  </section>

  <modal :show="showModal" @close="showModal = false">
    <template #header><h1>{{modalHeader}}</h1></template>
    <template #body>
      <div class="uk-flex uk-flex-center">
        <div v-if="!modalMessage" uk-spinner="ratio: 3"></div>
      </div>
      <p v-if="modalMessage !== null">{{modalMessage}}</p>
      <!--<p v-if="modalMessage !== null">Email makléře: {{emailmakler}}</p>-->
    </template>
    <template #footer>
      <div v-if="!modalMessage"></div>
    </template>
  </modal>

</template>

<script>
import modal from "@/components/general/modal";
export default {
  name: "changeAdvert",
  components: {modal},
  props: ['requestType'],
  created() {
    this.id = this.$route.params.id;
    this.requestType = this.$route.params.requestType;;
    let add = this.$store.getters.advertById(this.id);
    this.add = add;
    if( !add.activated){
      this.$router.push({ path: '/' });
      return;
    }

    if( add.emailmakler){
      this.emailmakler = add.emailmakler;
    }
  },
  data(){
    return{
      id: null,
      message: '',
      validated: false,
      token: null,
      emailmakler: null,
      requestType: null,
      showModal: false,
      modalMessage: null,
      modalHeader: 'Odesílám',
    }
  },
  methods: {
    async send(){
      this.validated = true;
      if( this.message.length == 0 ){
        return;
      }
      this.modalMessage = null;
      this.showModal = true;

      if( !this.emailmakler ){
        await this.getEmailMakler()
      }


      let data = {
        "emailmakler": this.emailmakler,
        "zprava": this.message,
        "id": this.add.remoteId,
        "typ": this.requestType
      };

      this.axios.post('kontaktovatmaklere/', data).then((response) => {
        if (response.data.status == "OK") {
          this.modalHeader = 'Odesláno';
        } else {
          this.modalHeader = 'Došlo k nějaké chybě';
        }
        this.modalMessage = response.data.info.name;
      });
    },
    async getEmailMakler(){
      await this.$store.dispatch("getToken").then((token) => this.token = token);
      await this.axios.post('zakazka/' + this.add.remoteId + '/', {"token": this.token}).then((response)=> {
        this.emailmakler = response.data.info.zak.emailmakler;
      });
    },
  },

}
</script>

<style scoped lang="less">
section.home-content{
  .container{
    padding: 0 40px;
    p{
      text-align: left;
      margin-bottom: 2rem;
      .description{
        font-weight: 700;
      }
      .text{
        display: block;
      }
    }
    fieldset{
      &.error{
        textarea{
          border-color: #AC2F2F;
        }
      }
      &.succes{
        textarea{
          border-color: #04ac66;
        }
      }
    }
  }
}

h2{
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 3rem;
  line-height: 7.6rem;
  text-align: center;
  color: #7C7C7B;
  margin-top: 0;
  margin-bottom: 1rem;
}
</style>